import React from 'react'
import { Link } from '@reach/router'
import styled from 'styled-components'
import darken from 'polished/lib/color/darken'

// icons
import { Question } from '@styled-icons/evil'

// utils
import th from 'utils/themeHelper'

// Load components synchronously
import Tooltip from 'components/Tooltip'

const ChildrenWrapper = styled.div`
  text-align: center;
  width: ${props => (props.hasIcon ? 'auto' : '100%')};
`

const ActionLink = styled(Link)`
  align-items: center;
  background: ${({ disabled, theme, overrideBg }) =>
    !!overrideBg ? overrideBg : disabled ? theme.backgrounds.mid : 'white'};
  border-color: ${props => props.overrideColor || th('backgrounds.mid')};
  color: ${props => props.overrideColor || th('text.dark')};
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  display: flex;
  font-family: ${th('fonts.bold')};
  font-size: 12px;
  justify-content: flex-start;
  line-height: 1.2em;
  min-height: ${props => props.minHeight || '35px'};
  outline: none;
  padding-bottom: ${th('spacing.tiny', 'px')};
  padding-left: ${props =>
    props.hasIcon ? th('spacing.small', 'px') : th('spacing.default', 'px')};
  padding-right: ${props => props.paddingRight || th('spacing.default', 'px')};
  padding-top: ${th('spacing.tiny', 'px')};
  text-align: left;
  transition: 0.3s background 0.3s border;
  width: ${props => (props.wide ? '100%' : 'auto')};

  &:hover,
  &:focus {
    background: ${({ disabled, theme, overrideBg, overrideBgHover }) => {
      return overrideBgHover
        ? overrideBgHover
        : overrideBg
        ? darken(0.05, '#379B75')
        : disabled
        ? theme.backgrounds.mid
        : 'white'
    }};
    border-color: ${props => props.overrideColorHover || props.overrideColor || th('text.dark')};
    color: ${props => props.overrideColorHover || props.overrideColor || th('text.dark')};
  }
`

const ActionLinkWithIcon = styled(ActionLink)`
  justify-content: center;
  padding-left: ${th('spacing.small', 'px')};
`

const ButtonLinkBase = styled.button`
  align-items: center;
  background: ${({ disabled, theme, overrideBg }) =>
    !!overrideBg ? overrideBg : disabled ? theme.backgrounds.mid : 'white'};
  border-color: ${props => props.overrideColor || th('backgrounds.mid')};
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  color: ${props => props.overrideColor || th('text.dark')};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  display: flex;
  font-family: ${th('fonts.bold')};
  font-size: 12px;
  justify-content: ${props => (props.hasIcon ? 'center' : 'flex-start')};
  line-height: 1.2em;
  min-height: ${props => props.minHeight || '35px'};
  min-width: ${props => props.minWidth || 'auto'};
  outline: none;
  padding-bottom: ${props => props.paddingBottom || th('spacing.tiny', 'px')};
  padding-left: ${props =>
    props.hasIcon ? th('spacing.small', 'px') : props.paddingLeft || th('spacing.default', 'px')};
  padding-right: ${props => props.paddingRight || th('spacing.default', 'px')};
  padding-top: ${props => props.paddingTop || th('spacing.tiny', 'px')};
  text-align: left;
  transition: 0.3s background 0.3s border;
  width: ${props => (props.wide ? '100%' : 'auto')};

  &:hover,
  &:focus {
    background: ${({ disabled, theme, overrideBg, overrideBgHover }) =>
      !!overrideBgHover
        ? overrideBgHover
        : !!overrideBg
        ? overrideBg
        : disabled
        ? theme.backgrounds.mid
        : 'white'};
    border-color: ${props => props.overrideColorHover || props.overrideColor || th('text.dark')};
    color: ${props => props.overrideColorHover || props.overrideColor || th('text.dark')};
    transition: 0.3s background 0.3s border;
  }
`

const ButtonLinkBaseWithIcon = styled.div`
  align-items: center;
  background: ${({ disabled, theme, overrideBg }) =>
    !!overrideBg ? overrideBg : disabled ? theme.backgrounds.mid : 'white'};
  border-color: ${props => props.overrideColor || th('backgrounds.mid')};
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  color: ${props => props.overrideColor || th('text.dark')};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  display: flex;
  font-family: ${th('fonts.bold')};
  font-size: 12px;
  justify-content: ${props => (props.hasIcon ? 'center' : 'flex-start')};
  line-height: 1.2em;
  min-height: ${props => props.minHeight || '35px'};
  min-width: ${props => props.minWidth || 'auto'};
  outline: none;
  padding-bottom: ${props => props.paddingBottom || th('spacing.tiny', 'px')};
  padding-left: ${props =>
    props.hasIcon ? th('spacing.small', 'px') : props.paddingLeft || th('spacing.default', 'px')};
  padding-right: ${props => props.paddingRight || th('spacing.default', 'px')};
  padding-top: ${props => props.paddingTop || th('spacing.tiny', 'px')};
  text-align: left;
  transition: 0.3s background 0.3s border;
  width: ${props => (props.wide ? '100%' : 'auto')};

  &:hover,
  &:focus {
    background: ${({ disabled, theme, overrideBg, overrideBgHover }) =>
      !!overrideBgHover
        ? overrideBgHover
        : !!overrideBg
        ? overrideBg
        : disabled
        ? theme.backgrounds.mid
        : 'white'};
    border-color: ${props => props.overrideColorHover || props.overrideColor || th('text.dark')};
    color: ${props => props.overrideColorHover || props.overrideColor || th('text.dark')};
    transition: 0.3s background 0.3s border;
  }
`

const ButtonIconContainer = styled.i`
  line-height: 20px;
  margin-right: ${th('spacing.small', 'px')};

  & > svg {
    margin-top: ${props => props.iconMarginTop || '0px'};
    width: ${props => props.iconSize || '20px'};
    height: ${props => props.iconSize || '20px'};
  }
`

const IconContainer = styled.i`
  line-height: 20px;
  margin-left: ${th('spacing.small', 'px')};

  & > svg {
    width: 20px;
    height: 20px;
  }
`

const LinkButton = ({ children, disabledHint, icon: Icon, to, ...props }) => {
  if (props.disabled && disabledHint) {
    return (
      <Tooltip position="top" content={disabledHint} forceShow isWarning>
        <ButtonLinkBase {...props} hasIcon={Icon}>
          <ChildrenWrapper hasIcon={Icon}>{children}</ChildrenWrapper>

          <IconContainer>
            <Question />
          </IconContainer>
        </ButtonLinkBase>
      </Tooltip>
    )
  }

  if (to && Icon) {
    return (
      <ActionLinkWithIcon to={to} {...props}>
        <ButtonIconContainer {...props}>
          <Icon />
        </ButtonIconContainer>

        <ChildrenWrapper hasIcon>{children}</ChildrenWrapper>
      </ActionLinkWithIcon>
    )
  }

  if (to) {
    return (
      <ActionLink to={to} {...props}>
        <ChildrenWrapper>{children}</ChildrenWrapper>
      </ActionLink>
    )
  }

  if (Icon) {
    return (
      <ButtonLinkBaseWithIcon {...props}>
        <ButtonIconContainer {...props}>
          <Icon />
        </ButtonIconContainer>

        <ChildrenWrapper hasIcon>{children}</ChildrenWrapper>
      </ButtonLinkBaseWithIcon>
    )
  }

  return (
    <ButtonLinkBase {...props}>
      <ChildrenWrapper>{children}</ChildrenWrapper>
    </ButtonLinkBase>
  )
}

export default LinkButton
