import './polyfills/index'
import React from 'react'
import * as Sentry from '@sentry/browser'
import { createRoot } from 'react-dom/client'
import App from './App'
import { hotjar } from 'react-hotjar'

const isDev = process.env.NODE_ENV === 'development'

if (!isDev) {
  Sentry.init({
    dsn: 'https://eaa2d79f615e47f0825556a2dd05c786@sentry.io/1231712',
    environment: process.env.REDUX_ENV,
    ignoreErrors: [
      /Loading chunk \d+ failed/,
      'google is not defined',
      /Blocked a frame with origin/,
      /Unexpected token '?<'?/,
      /SecurityError: Failed to read the 'localStorage' property from 'Window': Access is denied for this document/
    ]
  })

  const hjid = 1888762
  const hjsv = 6
  hotjar.initialize(hjid, hjsv)
}

const container = document.getElementById('root')
const root = createRoot(container)
root.render(<App />)
