import React from 'react'
import styled, { css } from 'styled-components'
import darken from 'polished/lib/color/darken'

// icons
import { Question } from '@styled-icons/evil'

// utils
import th from 'utils/themeHelper'

// Load components synchronously
import Tooltip from 'components/Tooltip'

const BaseStyle = styled.button`
  outline: none;
  font-size: 12px;
  font-family: ${th('fonts.bold')};
  display: ${props => (props.disabledHint ? 'flex' : 'block')};
  border-radius: 4px;
  padding-bottom: ${th('spacing.tiny', 'px')};
  padding-top: ${th('spacing.tiny', 'px')};
  padding-left: ${th('spacing.default', 'px')};
  padding-right: ${props =>
    props.hasHint ? th('spacing.small', 'px') : th('spacing.default', 'px')};
  border-width: 1px;
  border-style: solid;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  width: ${props => (props.wide ? '100%' : 'auto')};
  min-height: ${props => props.height || '35px'};
  transition: 0.3s background 0.3s border;
  text-align: left;
  line-height: 1.75em;
  white-space: nowrap;

  &:hover,
  &:focus {
    background: ${({ theme, disabled, warning }) =>
      disabled
        ? theme.backgrounds.mid
        : warning
        ? theme.backgrounds.warning
        : darken(0.05, theme.primary.base)};
    border-color: ${({ theme, disabled, warning }) =>
      disabled ? theme.backgrounds.mid : warning ? theme.backgrounds.warning : theme.primary.base};
    transition: 0.3s background 0.3s border;
  }
`

const ButtonBase = styled(BaseStyle)`
  color: ${({ disabled, theme }) => (disabled ? theme.text.dark : 'white')};
  border-color: ${({ disabled, theme, warning }) =>
    disabled ? theme.backgrounds.mid : warning ? theme.backgrounds.error : theme.primary.base};
  background: ${({ disabled, theme, warning }) =>
    disabled ? theme.backgrounds.mid : warning ? theme.backgrounds.error : theme.primary.base};
  text-align: center;

  ${({ disabled, hasIcon }) =>
    hasIcon
      ? css`
          align-items: center;
          display: flex;
          justify-content: start;
          padding-left: ${disabled ? '20px' : '12px'};
          padding-right: ${disabled ? '20px' : '12px'};
          width: 100%;
        `
      : css`
          display: block;
        `}
`

const IconContainer = styled.i`
  line-height: 20px;

  & > svg {
    ${({ iconFillColor }) => {
      if (iconFillColor) {
        return css`
          fill: ${iconFillColor};
        `
      }

      return css`
        fill: white;
      `
    }}

    ${({ iconTop }) =>
      iconTop &&
      css`
        top: ${iconTop};
      `}

    height: 20px;
    margin-left: 5px;
    margin-right: ${props => {
      if (props.content && props.content.length > 0) {
        return '12px'
      }

      return '5px'
    }};
    position: relative;
    width: 20px;
  }
`

const QuestionIconContainer = styled.i`
  line-height: 20px;
  margin: 0 10px 0 5px;

  & > svg {
    width: 20px;
    height: 20px;
  }
`

const SmallButtonPrimary = ({ children, disabled, disabledHint, icon: Icon, ...props }) => {
  const content = children ? children.toString() : ''

  if (disabled && disabledHint) {
    return (
      <Tooltip position="right" content={disabledHint} forceShow isWarning minWidth>
        <ButtonBase disabled={disabled} {...props} disabledHint={disabledHint} hasIcon>
          <QuestionIconContainer>
            <Question />
          </QuestionIconContainer>

          <div>{children}</div>
        </ButtonBase>
      </Tooltip>
    )
  }

  if (Icon) {
    return (
      <ButtonBase hasIcon {...props}>
        <IconContainer content={content} {...props}>
          <Icon />
        </IconContainer>

        {children}
      </ButtonBase>
    )
  }

  return <ButtonBase {...props}>{children}</ButtonBase>
}

export default SmallButtonPrimary
